import {
  Link
} from "react-router-dom";
import React, { useState } from 'react';

function StepOne() {
  const startingX = window.innerWidth / 2;
  const startingY = window.innerHeight / 2;
  const [x, setX] = useState(startingX);
  const [y, setY] = useState(startingY);
  const [foodType, setFoodType] = useState("taco");

  function randomFood() {
    const h = window.innerHeight - 200;
    const w = window.innerWidth - 200;
    const el = document.getElementById('animate');
    el.style.display = "unset";
    el.style.opacity = "1";
    let nh = Math.floor(Math.random() * h);
    let nw = Math.floor(Math.random() * w);

    if(foodType === "taco") {
      console.log('How do chickens kiss? A peck');
      setFoodType("hamburger");
    }

    if(foodType === "hamburger") {
      console.log('What do tacos eat for lunch? Tacos');
      setFoodType("chicken");
    }

    if(foodType === "chicken") {
      console.log('What is a hamburgers joke called? A bun');
      setFoodType("taco");
    }
    
    setTimeout(function(){ 
      setX(nw);
      setY(nh);
    }, 50);

    
    setTimeout(function(){ 
      el.style.opacity = "0";
      setX(startingX);
      setY(startingY);
    }, 500);
    
  }

  const chooseFoodType = () => {
    if(foodType === "taco") {
      return '/images/hamburger.png';
    }

    if(foodType === "hamburger") {
      return '/images/chicken.png';
    }

    if(foodType === "chicken") {
      return '/images/taco.png';
    }
  }

  return(
    <>
    <div className='container'>
      <section className='infoHome'>
        <h1>"What should we eat?"</h1>
        <p className="supportText">Tired of not being able to decide where to eat?</p>
        <p className="supportText"> Randomly choose a restaraunt near you.</p>
        <Link to='/random'><button className={'chooseBtn'}>Choose</button></Link>
      </section>
      <img src={'/images/primary.svg'} className={'hero'} onClick={() => randomFood()}/>
      <div id={'animate'} style={{"top": `${y}px`, "left": `${x}px`}}>
        <img id="food" src={chooseFoodType()}/>
      </div>

    </div>
    </>
  );
}
export default StepOne;
