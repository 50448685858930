import './App.scss';
import StepOne from './components/StepOne';
import Random from './components/Random';
// import Vote from './components/Vote';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

function App() {
  return (
    <Router>
      <div className="App">
      <AppBar position="static" style={{ backgroundColor: '#92e3a9'}}>        
      <Toolbar>
      <Link className="mainTitle" to="/">
      <Typography variant="h6">
       Dinner
      </Typography>
      <img className="foodNav" src={'/images/hamburger.png'} />
    </Link>
    <Link to="/#" className="vote">
    <Button className="comingSoon" color="default">Voting coming soon</Button>
    </Link>
    </Toolbar>
  </AppBar>
        <Switch>
          <Route exact path="/" component={StepOne}/>
          <Route path="/random"component={Random}/>
          {/* <Route path="/vote" component={Vote}/> */}
        </Switch>
      </div>
    </Router>
  );
}

export default App;
