import React, { useState, useEffect } from 'react';
import CheckIcon from '@material-ui/icons/Check';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import Loader from "react-loader-spinner";
import quota from './quota.svg';
const useStyles = makeStyles({
  root: {
    maxWidth: 625,
  },
  media: {
    height: 140,
  }
});

const openIcon = () => (
  <>
  Open <CheckIcon style={{ color: green[500] }}/>
</>
);

const spinnerTypes = ['Audio', 'Puff', 'BallTriangle', 'Bars', 'Circles', 'Grid', 'Hearts', 'Oval', 'TailSpin', 'Rings', 'ThreeDots', 'MutatingDots', 'RevolvingDot', 'Watch'];
const randomType = spinnerTypes[Math.floor((Math.random()*spinnerTypes.length))];

const LoadingSpinner = () => (
  <div className='loader'>
      <Loader
      type={randomType}
      color="#ff5148"
      height={100}
      width={100}
    />
  </div>
);

function Random() {
  const classes = useStyles();
  const [selection, setSelection] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [overQueryLimit, setOverQueryLimit] = useState(false);
  const [placeDeets, setPlaceDeets] = useState([]);

  function formatPhotos(photo) {
    const photoUrl = photo.getUrl();
    return (
    <div className="imageContainer">
      <img className={'image'} src={photoUrl} />
    </div>
    );
  }
  
  function noPhotos() {
    return (
      <div className="emptyResponse">
        No photos
      </div>
    );
  }

  function noReviews() {
    return (
      <div className="emptyResponse">
        No reviews
      </div>
    );
  }

  function formatReviews(reviews) {
    return reviews.map((review) => {
      return (
      <div key={review.author_name} className="review">
        <section className="reviewPrimaryInfo">
          <img className="authorProfilePicture" src={review.profile_photo_url}/>
          <div className="authorName">{review.author_name}</div>
          <div className="reviewRating">{review.rating} / 5</div>
        </section>
        <p className="reviewText">
          <FormatQuoteIcon className="quoteIcon"/>
          {review.text}
        </p>
      </div>
      );
    });
  }
const Final = () => (
  <>

  <div className='selection'>
    <div id="map"></div>

  
    <section className="selection_details">
      <h2 className="name">You are eating at {selection.name}</h2>
      <div>{selection.vicinity}</div>
      <div className="opening_hours">{selection.opening_hours && selection.opening_hours.isOpen ? openIcon()   : 'Closed'}</div>
      <div>{placeDeets && placeDeets.formatted_phone_number}</div>
    </section>


    <section className="restarauntInfo">
    <div className={classes.root}>
      {placeDeets.photos ? formatPhotos(placeDeets.photos[0]) : noPhotos()}
    </div>
    <div className="reviewsContainer">
      {placeDeets.reviews ? formatReviews(placeDeets.reviews) : noReviews()}
    </div>
    </section>
  </div>
    </>
);
function Decision() {
  if(isLoading) {
    return (
      <>
      <div id="map"></div>
      <LoadingSpinner />
      </>
    );
  } 
  if (overQueryLimit) {
    return (
      <>
       <img className="quota" src={quota} />;
       <p>We have reached our limit for the day. Come back tomorrow</p>
       </>
    );
  }
  else {
    return <Final />;
   }
}
useEffect(() => {
  let pos;
  setIsLoading(true);
  navigator.geolocation.getCurrentPosition(position => {
  pos = {
    lat: position.coords.latitude,
    lng: position.coords.longitude
  };
  const request = {
    location: pos,                                                                                                                                                                                                        
    rankBy: window.google.maps.places.RankBy.DISTANCE,
    keyword: 'food',
    type: 'restaraunt',
    fields: ['name', 'rating', 'formatted_phone_number', 'geometry', 'photos']
  };
  const map = document.getElementById("map");

  let service = new window.google.maps.places.PlacesService(map);
  service.nearbySearch(request, (results, status) => {
    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
      setOverQueryLimit(false);
      const random = Math.floor(Math.random() * results.length);

      setSelection(results[random]);
      setIsLoading(false);
      const secondRequest = {
        placeId: results[random].place_id,
        fields: ['name', 'rating', 'formatted_phone_number', 'geometry', 'photos', 'reviews']
      };
      let nextService = new window.google.maps.places.PlacesService(map);
      nextService.getDetails(secondRequest, (results, status)  => {
        setPlaceDeets(results);
      });
    }
    if (status === 'OVER_QUERY_LIMIT') {
      setIsLoading(false);
      setOverQueryLimit(true);
    }
  });

});
}, []);

  return (
    <Decision />
  );
}

export default Random;
